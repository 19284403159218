#megamenu{
    @include media-breakpoint-up(lg) { 
        justify-content: center; 
    }

    .navbar-nav{
        >li {
            @include media-breakpoint-up(lg) {
                &:not(:last-child){
                    margin-right: 50px;
                }
            }
        }
    }
}   

.navbar-nav{
    *{
        color: color("black");
    }
    ul {
        list-style: none;
        padding: 0;
    }
    a{
        transition: all ease .3s;
        i {
            transition: all ease .3s;
        }
        &:hover{
            color: color("red");
            > i {
                color: color("red");
            }
        }
    }

    .menu{ 

        &_container{
            display: flex;
            position: absolute;
            z-index: 1;
            left: 0;
            right: 0;
            top: 60px;
            background-color: #F5F6F7;
            min-height: 500px;
            align-items: center;
            transform: scaleY(0);
            transform-origin: top;
            transition: all ease .3s;

            .container {
                justify-content: start;
                transform: scale(0);
                transition: all ease .3s;
            }

            &.open{
                transform: scaleY(1);
                > .container{
                    transform: scale(1);
                }
            }
            
        }

        &_lv_2{
           
            &::before{
                position: absolute;
                right: 0;
                bottom: 0;
                content:"";
                height: 100%;
                max-height: 248px;
                width: 2px;
                background-color: #DDDEDE;
            }

            > li{

                &:first-child{
                    display: flex;
                    align-items: baseline;
                }

                cursor: pointer;
                padding-right: 86px;
                &:first-child{
                    span {
                        @include fluid-font(24px, 28px);
                        font-weight: bold;
                        letter-spacing: 0;
                        line-height: 33px;
                        margin-right: 22px;
                    }
                    a{
                        font-size: 15px;
                        letter-spacing: 0;
                        line-height: 17px;
                    }
                }
                font-size: 18px;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 46px;

                span {
                    transition: all ease .3s;
                    display: flex;
                    align-items: center;
                    i{
                        margin-left: 8px;
                        font-size: 12px;
                        color: color("grey"); 
                        transition: all ease .3s;

                    }
                    &:hover{
                        color: color("red");
                        i{
                            font-size: 16px;
                            color: color("black");
                        }
                    }
                }
            }
        }

        &_lv_3{
            display: flex;
            z-index: -1;
            position: absolute;
            right: -660px;
            top: 50%;
            transform: translateY(-50%);
            width: 500px;
            flex-wrap: wrap;
            opacity: 0;
            transition: opacity ease-in .3s;

            @include media-breakpoint-down(lg) {
                right: -560px;
            }

            &.open{ 
                z-index: 1;          
                opacity: 1;
            }
            li{ 
                width: 50%;
                margin: 0;
                margin-bottom: 0.6rem;
                a{
                    font-size: 16px;
                    line-height: 31px;
                    display: block;
                    letter-spacing: 0;
                    font-weight: 400;
                    height: 100%;
                }
            }

            &__space_3 {
                li{
                    width: 33%; 
                }
            }
        }
    }

    .fa-arrow-right{
        font-size: 12px;
    }
}

.faqs-menu{
    a{
        font-size: 15px;
        letter-spacing: 0;
        line-height: 17px;
        font-weight: 400;
    }
}

.navbar-toggler{
    border: none;
    &:focus{
        outline: none;
    }
}
.c-logo-rwd{
    max-width: 70%;
}

.navbar-rwd{
    list-style: none;
    padding: 0;

    li > * {
        font-size: 22px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 61px;
    }
}

.c-a-div{
    a{
        font-size: 16px;
        letter-spacing: 0;
        line-height: 19px;
        color: color("black");
        display: block;

        &:nth-child(2){
            margin-top: 18px;
        }
    }
}
.close-megamenu{
    display: block;
    margin-bottom: auto;
    POSITION: relative;
    right: 100px;
    font-size: 34px;
    top: 40px;
    i{
        color: color("black") !important; 
    }
}

@include media-breakpoint-down(md) {
    .navbar{
        background-color: white;
        padding: 0;

        &.open{
            position: fixed;
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            z-index: 1;
        }
    }

    .c-logo-rwd, .navbar-toggler{
        padding: 1rem;
    }
    .navbar-toggler{
        padding-left: 0;
    }
    .c-logo-rwd{
        padding-right: 0;
    }

    .sticky-nav{
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 1;
        animation-name: scrolled-in;
        animation-duration: 1s;

        &.out{
            top: -100px;

            animation-name: scrolled-out;
            animation-duration: .5s;
        }
    }

    @keyframes scrolled-in {
        from { top: -100px;}
        to { top:0;}
    }

    @keyframes scrolled-out {
        from { top: 0px;}
        to { top:-100;}
    }

    #megamenu-rwd{
        width: 100%; 
        padding-left: 34px;

        ul {
            list-style: none;
        }

        *:not(.fa-chevron-left){
            color: color("black");
        }

        &.show, &.collapsing, .menu_lv_2, .menu_lv_3{
            min-height: calc(100vh - 90px); 
            display: flex;
            flex-direction: column;
        } 

        i{
            font-size: 14px;
            margin-left: auto;
            margin-right: 20px;
        }



        .menu_lv_2{
            position: fixed;
            top: 92px;
            left: 100%;
            right: 0;
            z-index: 20;
            background-color: color("grey-bg");
            opacity: 0;
            transition: all ease .3s;

            > li:not(:first-child){
                > a, > span{
                    font-size: 16px;
                    font-weight: bold;
                    letter-spacing: 0;
                    display: flex;
                    align-items: center;

                    > .fa-chevron-right{
                        margin-left: auto;
                    }
                }
            }
            > li{
                &:first-child {
                    padding-bottom: 1rem;
                    position: relative; 
                    &::after{
                        content: "";
                        position: absolute;
                        width: 100%;
                        height: 2px;
                        background-color: #E8EBED;
                        left: -40px;
                        right: 0;
                        bottom: 0;
                        width: calc(100% + 40px);
                    }

                    .volver {
                        font-size: 16px;
                        letter-spacing: 0;
                        line-height: 19px;
                        font-weight: 400;
                        color: color("grey");
                    }
                }

                &:nth-child(2){
                    > span, > a{
                        font-size: 22px;
                        font-weight: bold;
                        letter-spacing: 0;
                        color: color("grey");
                    }  
                }
            }
            
        
            &.open{
                left: 0;
                opacity: 1;
            }

            @media( max-width: 578px) and (max-height: 720px ){
                overflow-y: scroll;
                bottom: 0;
            }
        }

        .menu_lv_3{
            position: fixed;
            top: 92px;
            left: 100%;
            right: 0;
            z-index: 20;
            background-color: color("grey-bg");
            opacity: 0;
            transition: all ease .3s;

            > li:not(:first-child){
                > a, > span{
                    font-size: 16px;
                    font-weight: bold;
                    letter-spacing: 0;
                    display: flex;
                    align-items: center;

                    > .fa-chevron-right{
                        margin-left: auto;
                    }
                }
            }
            > li{
                &:first-child {
                    padding-bottom: 1rem;
                    position: relative; 
                    &::after{
                        content: "";
                        position: absolute;
                        width: 100%;
                        height: 2px;
                        background-color: #E8EBED;
                        left: -40px;
                        right: 0;
                        bottom: 0;
                        width: calc(100% + 40px);
                    }

                    .volver {
                        font-size: 16px;
                        letter-spacing: 0;
                        line-height: 19px;
                        font-weight: 400;
                        color: color("grey");
                    }
                }
            }
            
        
            &.open{
                left: 0;
                opacity: 1;
                bottom: 0;
                overflow-y: scroll;
            }

            @media( max-width: 578px) and (max-height: 720px ){
                overflow-y: scroll;
                bottom: 0;
            }
        }
        .volver{
            margin-top: 44px;
            font-size: 16px;
            letter-spacing: 0;
            line-height: 19px;
            color: color("grey");
            border-bottom: 1.5px solid color("grey-bg");
        }

        .c-a-div{
            margin-top: auto;
            margin-bottom: 50px;
        }

    }
}

.no-overflow{
    overflow: hidden;
}