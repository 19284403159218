footer {
    background-color: color("black");
    padding-top: 60px;
    padding-bottom: 14px;

    @include media-breakpoint-down(lg) {
        .logo-footer {
            max-width: 255px;
            display: block;
            margin: auto;
            margin-bottom: 1rem;
        }
    }

}

.f-lista {
    list-style: none;
    padding: 0;

    li {
        a {
            font-size: 14px;
            font-weight: 300;
            letter-spacing: 0;
            line-height: 24px;
            color: white;
            display: block;

            @include media-breakpoint-down(xs) {
                line-height: 20px;
                margin: 8px 0;
            }
        }

        &:first-child {
            font-size: 14px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 16px;
            color: color("grey");
            margin-bottom: 0.8rem;
        }
    }
}

.r-sociales {
    a:not(:last-child) {
        margin-right: 8px;
    }

    img {
        max-width: 21px;
        height: auto;
    }

    @include media-breakpoint-down(xs) {
        justify-content: center;
        display: flex;
        margin-bottom: 15px;
    }
}

.derechos {
    color: color("grey");
    font-size: 13px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 15px;
}

.separador-footer {
    border: 1px solid #787a7c;
    opacity: .23;
    margin-top: 20px;
    margin-bottom: 20px;
}

.credit-cards {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;

    @include media-breakpoint-down(xs) {
        justify-content: center;
        margin-bottom: 15px;
    }
}

.credit_card {
    width: 27px;
    height: 19px;
    background: url("../../../assets/img/logos/credictcard.png") no-repeat;
    display: inline-block;
    margin-left: 5px;
}

credit_card.amazon {
    background-position: -123px -131px;
}

.credit_card.amex {
    background-position: 0px 0px;
}

.credit_card.apple {
    background-position: -81px -131px;
}

.credit_card.c200 {
    background-position: -164px -98px;
}

.credit_card.cirrus {
    background-position: -41px -33px;
}

.credit_card.delta {
    background-position: -0px -65px;
}

.credit_card.discover {
    background-position: -81px -33px;
}

.credit_card.direct-debit {
    background-position: -41px -98px;
}

.credit_card.google {
    background-position: -0px -98px;
}

.credit_card.mastercard {
    background-position: -163px 0px;
}

.credit_card.maestro {
    background-position: -0px -33px;
}

.credit_card.moneybookers {
    background-position: -0px -131px;
}

.credit_card.moneygram {
    background-position: -123px -98px;
}

.credit_card.novus {
    background-position: -41px -131px;
}

.credit_card.paypal-1 {
    background-position: -41px -65px;
}

.credit_card.paypal-2 {
    background-position: -81px -65px;
}

.credit_card.plain {
    background-position: -164px -131px;
}

.credit_card.sage {
    background-position: -164px -65px;
}

.credit_card.solo {
    background-position: -164px -33px;
}

.credit_card.switch {
    background-position: -123px -33px;
}

.credit_card.visa {
    background-position: -41px 1px;
}

.credit_card.visa-debit {
    background-position: -81px 0px;
}

.credit_card.visa-electron {
    background-position: -123px 0px;
}

.credit_card.western-union {
    background-position: -82px -98px;
}

.credit_card.worldpay {
    background-position: -123px -65px;
}
