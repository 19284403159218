.color-red {
  color: #E85746; }

.bg-red {
  background-color: #E85746; }

.color-red-opacy {
  color: #E8574688; }

.bg-red-opacy {
  background-color: #E8574688; }

.color-black {
  color: #1E1E1C; }

.bg-black {
  background-color: #1E1E1C; }

.color-grey {
  color: #787A7C; }

.bg-grey {
  background-color: #787A7C; }

.color-grey-bg {
  color: #F5F6F7; }

.bg-grey-bg {
  background-color: #F5F6F7; }

.color-pink {
  color: #F9E0DD; }

.bg-pink {
  background-color: #F9E0DD; }

.color-white {
  color: #FFFFFF; }

.bg-white {
  background-color: #FFFFFF; }

.color-white_1 {
  color: #F5F6F7; }

.bg-white_1 {
  background-color: #F5F6F7; }

.color-yellow {
  color: #dd9933; }

.bg-yellow {
  background-color: #dd9933; }

.color-yellow-hover {
  color: #F3C300; }

.bg-yellow-hover {
  background-color: #F3C300; }

@font-face {
  font-family: 'Public Sans';
  src: url("/assets/fonts/public_sans/static/PublicSans-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: 400; }

@font-face {
  font-family: 'Public Sans';
  src: url("/assets/fonts/public_sans/static/PublicSans-Bold.ttf") format("truetype");
  font-style: normal;
  font-weight: 700; }

@font-face {
  font-family: 'Public Sans';
  src: url("/assets/fonts/public_sans/static/PublicSans-Black.ttf") format("truetype");
  font-style: normal;
  font-weight: 900; }

.public-sans {
  font-family: 'Public Sans', sans-serif; }

h1, h2, h3, p, span, a {
  font-family: 'Public Sans', sans-serif; }

p {
  color: #1E1E1C;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 25px; }

.f-13 {
  font-size: 13px;
  font-size: calc(12px + (13 - 12) * ((100vw - 360px) / (1600 - 360)));
  letter-spacing: 0;
  line-height: 17px; }
  @media screen and (max-width: 360px) {
    .f-13 {
      font-size: 12px; } }
  @media screen and (min-width: 1600px) {
    .f-13 {
      font-size: 13px; } }

.f-15 {
  font-size: 15px;
  font-size: calc(14px + (15 - 14) * ((100vw - 360px) / (1600 - 360)));
  letter-spacing: 0;
  line-height: 19px; }
  @media screen and (max-width: 360px) {
    .f-15 {
      font-size: 14px; } }
  @media screen and (min-width: 1600px) {
    .f-15 {
      font-size: 15px; } }

.f-16 {
  font-size: 16px;
  font-size: calc(15px + (16 - 15) * ((100vw - 360px) / (1600 - 360)));
  letter-spacing: 0;
  line-height: 19px; }
  @media screen and (max-width: 360px) {
    .f-16 {
      font-size: 15px; } }
  @media screen and (min-width: 1600px) {
    .f-16 {
      font-size: 16px; } }

.f-18 {
  font-size: 18px;
  font-size: calc(17px + (18 - 17) * ((100vw - 360px) / (1600 - 360)));
  letter-spacing: 0;
  line-height: 21px; }
  @media screen and (max-width: 360px) {
    .f-18 {
      font-size: 17px; } }
  @media screen and (min-width: 1600px) {
    .f-18 {
      font-size: 18px; } }

.f-22 {
  font-size: 22px;
  font-size: calc(18px + (22 - 18) * ((100vw - 360px) / (1600 - 360)));
  letter-spacing: 0;
  line-height: 26px; }
  @media screen and (max-width: 360px) {
    .f-22 {
      font-size: 18px; } }
  @media screen and (min-width: 1600px) {
    .f-22 {
      font-size: 22px; } }

.f-28 {
  font-size: 28px;
  font-size: calc(22px + (28 - 22) * ((100vw - 360px) / (1600 - 360)));
  letter-spacing: 0;
  line-height: 35px; }
  @media screen and (max-width: 360px) {
    .f-28 {
      font-size: 22px; } }
  @media screen and (min-width: 1600px) {
    .f-28 {
      font-size: 28px; } }

.fw-bold {
  font-weight: bold; }

.fw-900 {
  font-weight: 900 !important; }

h1 {
  margin: 0; }

.btn:focus {
  outline: none;
  box-shadow: none; }

a {
  color: #E85746;
  transition: all ease .3s; }
  a:hover {
    text-decoration: none;
    color: #dd9933; }

.btn {
  cursor: pointer; }
  .btn:focus {
    box-shadow: none; }
  .btn__descaraga {
    background-color: #E85746;
    color: white;
    padding: 18px 42px;
    text-align: center;
    display: inline-block;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 17px;
    transition: background-color ease .3; }
    .btn__descaraga:hover {
      color: white;
      background-color: #dd9933; }
  .btn__primario {
    background-color: #E85746;
    width: 250px;
    padding: 1rem 0;
    color: white !important;
    border-radius: 0; }
  .btn__leer {
    color: #1E1E1C !important;
    text-decoration: underline !important; }
  .btn__submit {
    background-color: #E85746;
    width: 165px;
    padding: 1rem 0;
    color: #FFFFFF !important;
    font-family: "Public Sans";
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 17px;
    text-align: center;
    margin-top: 1rem;
    border-radius: 0 !important; }

.mt-70 {
  margin-top: 70px; }
  @media (max-width: 991.98px) {
    .mt-70 {
      margin-top: 50px; } }
  @media (max-width: 767.98px) {
    .mt-70 {
      margin-top: 32px; } }

.mt-50 {
  margin-top: 50px; }
  @media (max-width: 991.98px) {
    .mt-50 {
      margin-top: 32px; } }
  @media (max-width: 767.98px) {
    .mt-50 {
      margin-top: 26px; } }

.mt-40 {
  margin-top: 40px; }
  @media (max-width: 991.98px) {
    .mt-40 {
      margin-top: 26px; } }
  @media (max-width: 767.98px) {
    .mt-40 {
      margin-top: 22px; } }

.my-40 {
  margin: 40px 0; }
  @media (max-width: 991.98px) {
    .my-40 {
      margin: 26px 0; } }
  @media (max-width: 767.98px) {
    .my-40 {
      margin: 22px 0; } }

.py-80 {
  padding-top: 80px;
  padding-bottom: 80px; }
  @media (max-width: 991.98px) {
    .py-80 {
      padding-top: 50px;
      padding-bottom: 50px; } }
  @media (max-width: 767.98px) {
    .py-80 {
      padding-top: 30px;
      padding-bottom: 30px; } }

.py-30 {
  padding: 30px 0; }
  @media (max-width: 991.98px) {
    .py-30 {
      padding: 25px 0; } }
  @media (max-width: 767.98px) {
    .py-30 {
      padding: 15px 0; } }

.mb-40 {
  margin-bottom: 40px; }
  @media (max-width: 991.98px) {
    .mb-40 {
      margin-bottom: 26px; } }
  @media (max-width: 767.98px) {
    .mb-40 {
      margin-bottom: 22px; } }

.pb-40 {
  padding-bottom: 40px; }
  @media (max-width: 991.98px) {
    .pb-40 {
      padding-bottom: 26px; } }
  @media (max-width: 767.98px) {
    .pb-40 {
      padding-bottom: 22px; } }

.mx-10 {
  margin: 10px auto; }

.border-bottom {
  border-bottom: 2px solid #DDDEDE; }

.mxw-16 {
  max-width: 1rem; }

.text-underline {
  text-decoration: underline; }

.top-bar__content {
  min-height: 45px; }
  .top-bar__content > div:first-child {
    margin-right: 30px; }
  @media (max-width: 575.98px) {
    .top-bar__content > div {
      margin: 10px 0; } }

.top-bar i {
  font-size: 20px;
  line-height: 1em;
  color: #FFFFFF;
  margin-right: 10px; }

.top-bar a {
  font-family: "Public Sans";
  font-size: 13px;
  letter-spacing: 0;
  line-height: 15px;
  transition: all ease .3s;
  color: #FFFFFF; }
  .top-bar a:hover {
    color: #dd9933; }

.container-logo {
  padding: 60px 0; }

.navbar {
  border-top: 1px solid #F5F6F7; }

@media (min-width: 992px) {
  .navbar-nav > li > * {
    font-size: 15px;
    letter-spacing: 0;
    line-height: 19px;
    font-weight: bold; }
  .navbar-nav .fa-chevron-down {
    font-size: 12px;
    transition: all ease .3s; }
  .navbar-nav > li.ml-70 {
    margin-left: 70px; }
  .navbar-nav button {
    transition: all ease .3s; }
  .navbar-nav button:hover {
    color: #E85746; }
    .navbar-nav button:hover i {
      color: #E85746; }
  .buscador-nav {
    cursor: pointer;
    width: 46px;
    height: 46px; }
    .buscador-nav .fa-search::after {
      content: "";
      position: absolute;
      z-index: -1;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 46px;
      height: 46px;
      border-radius: 50px;
      background-color: #F5F6F7; }
  .buscador-container {
    background-color: white;
    position: absolute;
    left: 0;
    right: 0;
    top: 60px;
    z-index: 2;
    width: 100%;
    padding-top: 40px;
    padding-bottom: 80px;
    transform: scaleY(0);
    transform-origin: top;
    transition: transform 0.26s ease; }
    .buscador-container .btn {
      background-color: #E85746;
      width: 82px;
      height: 67px; }
      .buscador-container .btn i {
        color: white !important;
        font-size: 28px; }
    .buscador-container .buscador {
      opacity: 0;
      transform: scale(0);
      transition: all ease 0.3s; }
    .buscador-container input {
      color: #B5B5B5 !important;
      font-family: "Public Sans";
      font-size: 26px;
      font-weight: bold;
      letter-spacing: -0.3px;
      line-height: 31px;
      padding: 17px 34px;
      border: 1px solid #1E1E1C !important;
      outline: none !important; }
    .buscador-container.open {
      transform: scaleY(1); }
      .buscador-container.open .buscador {
        opacity: 1;
        transform: scale(1); }
  .lupa {
    width: 100%;
    height: 100%; }
  .container-cabecera {
    z-index: 10;
    transition: all ease .3s; }
    .container-cabecera.sticky {
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      background-color: white;
      animation-name: scrolled-in-d;
      animation-duration: 1s; }
    .container-cabecera.out {
      top: -256px;
      animation-name: scrolled-out-d;
      animation-duration: .5s; }
  @keyframes scrolled-in-d {
    from {
      top: -256px; }
    to {
      top: 0; } }
  @keyframes scrolled-out-d {
    from {
      top: 0px; }
    to {
      top: -256px; } } }

.pt-256 {
  padding-top: 256px; }

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  padding: 15px 15px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible; }

.hamburger:hover {
  opacity: 0.7; }

.hamburger.is-active:hover {
  opacity: 0.7; }

.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
  background-color: #000; }

.hamburger-box {
  width: 40px;
  height: 24px;
  display: inline-block;
  position: relative; }

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px; }

.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  width: 40px;
  height: 4px;
  background-color: #000;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease; }

.hamburger-inner::before, .hamburger-inner::after {
  content: "";
  display: block; }

.hamburger-inner::before {
  top: -10px; }

.hamburger-inner::after {
  bottom: -10px; }

/*
     * 3DX
     */
.hamburger--3dx .hamburger-box {
  perspective: 80px; }

.hamburger--3dx .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dx .hamburger-inner::before, .hamburger--3dx .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dx.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateY(180deg); }

.hamburger--3dx.is-active .hamburger-inner::before {
  transform: translate3d(0, 10px, 0) rotate(45deg); }

.hamburger--3dx.is-active .hamburger-inner::after {
  transform: translate3d(0, -10px, 0) rotate(-45deg); }

/*
     * 3DX Reverse
     */
.hamburger--3dx-r .hamburger-box {
  perspective: 80px; }

.hamburger--3dx-r .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dx-r .hamburger-inner::before, .hamburger--3dx-r .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dx-r.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateY(-180deg); }

.hamburger--3dx-r.is-active .hamburger-inner::before {
  transform: translate3d(0, 10px, 0) rotate(45deg); }

.hamburger--3dx-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -10px, 0) rotate(-45deg); }

/*
     * 3DY
     */
.hamburger--3dy .hamburger-box {
  perspective: 80px; }

.hamburger--3dy .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dy .hamburger-inner::before, .hamburger--3dy .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dy.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateX(-180deg); }

.hamburger--3dy.is-active .hamburger-inner::before {
  transform: translate3d(0, 10px, 0) rotate(45deg); }

.hamburger--3dy.is-active .hamburger-inner::after {
  transform: translate3d(0, -10px, 0) rotate(-45deg); }

/*
     * 3DY Reverse
     */
.hamburger--3dy-r .hamburger-box {
  perspective: 80px; }

.hamburger--3dy-r .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dy-r .hamburger-inner::before, .hamburger--3dy-r .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dy-r.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateX(180deg); }

.hamburger--3dy-r.is-active .hamburger-inner::before {
  transform: translate3d(0, 10px, 0) rotate(45deg); }

.hamburger--3dy-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -10px, 0) rotate(-45deg); }

/*
     * 3DXY
     */
.hamburger--3dxy .hamburger-box {
  perspective: 80px; }

.hamburger--3dxy .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dxy .hamburger-inner::before, .hamburger--3dxy .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dxy.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateX(180deg) rotateY(180deg); }

.hamburger--3dxy.is-active .hamburger-inner::before {
  transform: translate3d(0, 10px, 0) rotate(45deg); }

.hamburger--3dxy.is-active .hamburger-inner::after {
  transform: translate3d(0, -10px, 0) rotate(-45deg); }

/*
     * 3DXY Reverse
     */
.hamburger--3dxy-r .hamburger-box {
  perspective: 80px; }

.hamburger--3dxy-r .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dxy-r .hamburger-inner::before, .hamburger--3dxy-r .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dxy-r.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateX(180deg) rotateY(180deg) rotateZ(-180deg); }

.hamburger--3dxy-r.is-active .hamburger-inner::before {
  transform: translate3d(0, 10px, 0) rotate(45deg); }

.hamburger--3dxy-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -10px, 0) rotate(-45deg); }

/*
     * Arrow
     */
.hamburger--arrow.is-active .hamburger-inner::before {
  transform: translate3d(-8px, 0, 0) rotate(-45deg) scale(0.7, 1); }

.hamburger--arrow.is-active .hamburger-inner::after {
  transform: translate3d(-8px, 0, 0) rotate(45deg) scale(0.7, 1); }

/*
     * Arrow Right
     */
.hamburger--arrow-r.is-active .hamburger-inner::before {
  transform: translate3d(8px, 0, 0) rotate(45deg) scale(0.7, 1); }

.hamburger--arrow-r.is-active .hamburger-inner::after {
  transform: translate3d(8px, 0, 0) rotate(-45deg) scale(0.7, 1); }

/*
     * Arrow Alt
     */
.hamburger--arrowalt .hamburger-inner::before {
  transition: top 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1); }

.hamburger--arrowalt .hamburger-inner::after {
  transition: bottom 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1); }

.hamburger--arrowalt.is-active .hamburger-inner::before {
  top: 0;
  transform: translate3d(-8px, -10px, 0) rotate(-45deg) scale(0.7, 1);
  transition: top 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22); }

.hamburger--arrowalt.is-active .hamburger-inner::after {
  bottom: 0;
  transform: translate3d(-8px, 10px, 0) rotate(45deg) scale(0.7, 1);
  transition: bottom 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22); }

/*
     * Arrow Alt Right
     */
.hamburger--arrowalt-r .hamburger-inner::before {
  transition: top 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1); }

.hamburger--arrowalt-r .hamburger-inner::after {
  transition: bottom 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1); }

.hamburger--arrowalt-r.is-active .hamburger-inner::before {
  top: 0;
  transform: translate3d(8px, -10px, 0) rotate(45deg) scale(0.7, 1);
  transition: top 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22); }

.hamburger--arrowalt-r.is-active .hamburger-inner::after {
  bottom: 0;
  transform: translate3d(8px, 10px, 0) rotate(-45deg) scale(0.7, 1);
  transition: bottom 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22); }

/*
     * Arrow Turn
     */
.hamburger--arrowturn.is-active .hamburger-inner {
  transform: rotate(-180deg); }

.hamburger--arrowturn.is-active .hamburger-inner::before {
  transform: translate3d(8px, 0, 0) rotate(45deg) scale(0.7, 1); }

.hamburger--arrowturn.is-active .hamburger-inner::after {
  transform: translate3d(8px, 0, 0) rotate(-45deg) scale(0.7, 1); }

/*
     * Arrow Turn Right
     */
.hamburger--arrowturn-r.is-active .hamburger-inner {
  transform: rotate(-180deg); }

.hamburger--arrowturn-r.is-active .hamburger-inner::before {
  transform: translate3d(-8px, 0, 0) rotate(-45deg) scale(0.7, 1); }

.hamburger--arrowturn-r.is-active .hamburger-inner::after {
  transform: translate3d(-8px, 0, 0) rotate(45deg) scale(0.7, 1); }

/*
     * Boring
     */
.hamburger--boring .hamburger-inner, .hamburger--boring .hamburger-inner::before, .hamburger--boring .hamburger-inner::after {
  transition-property: none; }

.hamburger--boring.is-active .hamburger-inner {
  transform: rotate(45deg); }

.hamburger--boring.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0; }

.hamburger--boring.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg); }

/*
     * Collapse
     */
.hamburger--collapse .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--collapse .hamburger-inner::after {
  top: -20px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear; }

.hamburger--collapse .hamburger-inner::before {
  transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--collapse.is-active .hamburger-inner {
  transform: translate3d(0, -10px, 0) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }

.hamburger--collapse.is-active .hamburger-inner::after {
  top: 0;
  opacity: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear; }

.hamburger--collapse.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(-90deg);
  transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
     * Collapse Reverse
     */
.hamburger--collapse-r .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--collapse-r .hamburger-inner::after {
  top: -20px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear; }

.hamburger--collapse-r .hamburger-inner::before {
  transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--collapse-r.is-active .hamburger-inner {
  transform: translate3d(0, -10px, 0) rotate(45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }

.hamburger--collapse-r.is-active .hamburger-inner::after {
  top: 0;
  opacity: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear; }

.hamburger--collapse-r.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(90deg);
  transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
     * Elastic
     */
.hamburger--elastic .hamburger-inner {
  top: 2px;
  transition-duration: 0.275s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); }

.hamburger--elastic .hamburger-inner::before {
  top: 10px;
  transition: opacity 0.125s 0.275s ease; }

.hamburger--elastic .hamburger-inner::after {
  top: 20px;
  transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55); }

.hamburger--elastic.is-active .hamburger-inner {
  transform: translate3d(0, 10px, 0) rotate(135deg);
  transition-delay: 0.075s; }

.hamburger--elastic.is-active .hamburger-inner::before {
  transition-delay: 0s;
  opacity: 0; }

.hamburger--elastic.is-active .hamburger-inner::after {
  transform: translate3d(0, -20px, 0) rotate(-270deg);
  transition-delay: 0.075s; }

/*
     * Elastic Reverse
     */
.hamburger--elastic-r .hamburger-inner {
  top: 2px;
  transition-duration: 0.275s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); }

.hamburger--elastic-r .hamburger-inner::before {
  top: 10px;
  transition: opacity 0.125s 0.275s ease; }

.hamburger--elastic-r .hamburger-inner::after {
  top: 20px;
  transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55); }

.hamburger--elastic-r.is-active .hamburger-inner {
  transform: translate3d(0, 10px, 0) rotate(-135deg);
  transition-delay: 0.075s; }

.hamburger--elastic-r.is-active .hamburger-inner::before {
  transition-delay: 0s;
  opacity: 0; }

.hamburger--elastic-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -20px, 0) rotate(270deg);
  transition-delay: 0.075s; }

/*
     * Emphatic
     */
.hamburger--emphatic {
  overflow: hidden; }

.hamburger--emphatic .hamburger-inner {
  transition: background-color 0.125s 0.175s ease-in; }

.hamburger--emphatic .hamburger-inner::before {
  left: 0;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, left 0.125s 0.175s ease-in; }

.hamburger--emphatic .hamburger-inner::after {
  top: 10px;
  right: 0;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, right 0.125s 0.175s ease-in; }

.hamburger--emphatic.is-active .hamburger-inner {
  transition-delay: 0s;
  transition-timing-function: ease-out;
  background-color: transparent !important; }

.hamburger--emphatic.is-active .hamburger-inner::before {
  left: -80px;
  top: -80px;
  transform: translate3d(80px, 80px, 0) rotate(45deg);
  transition: left 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1); }

.hamburger--emphatic.is-active .hamburger-inner::after {
  right: -80px;
  top: -80px;
  transform: translate3d(-80px, 80px, 0) rotate(-45deg);
  transition: right 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1); }

/*
     * Emphatic Reverse
     */
.hamburger--emphatic-r {
  overflow: hidden; }

.hamburger--emphatic-r .hamburger-inner {
  transition: background-color 0.125s 0.175s ease-in; }

.hamburger--emphatic-r .hamburger-inner::before {
  left: 0;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, left 0.125s 0.175s ease-in; }

.hamburger--emphatic-r .hamburger-inner::after {
  top: 10px;
  right: 0;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, right 0.125s 0.175s ease-in; }

.hamburger--emphatic-r.is-active .hamburger-inner {
  transition-delay: 0s;
  transition-timing-function: ease-out;
  background-color: transparent !important; }

.hamburger--emphatic-r.is-active .hamburger-inner::before {
  left: -80px;
  top: 80px;
  transform: translate3d(80px, -80px, 0) rotate(-45deg);
  transition: left 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1); }

.hamburger--emphatic-r.is-active .hamburger-inner::after {
  right: -80px;
  top: 80px;
  transform: translate3d(-80px, -80px, 0) rotate(45deg);
  transition: right 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1); }

/*
     * Minus
     */
.hamburger--minus .hamburger-inner::before, .hamburger--minus .hamburger-inner::after {
  transition: bottom 0.08s 0s ease-out, top 0.08s 0s ease-out, opacity 0s linear; }

.hamburger--minus.is-active .hamburger-inner::before, .hamburger--minus.is-active .hamburger-inner::after {
  opacity: 0;
  transition: bottom 0.08s ease-out, top 0.08s ease-out, opacity 0s 0.08s linear; }

.hamburger--minus.is-active .hamburger-inner::before {
  top: 0; }

.hamburger--minus.is-active .hamburger-inner::after {
  bottom: 0; }

/*
     * Slider
     */
.hamburger--slider .hamburger-inner {
  top: 2px; }

.hamburger--slider .hamburger-inner::before {
  top: 10px;
  transition-property: transform, opacity;
  transition-timing-function: ease;
  transition-duration: 0.15s; }

.hamburger--slider .hamburger-inner::after {
  top: 20px; }

.hamburger--slider.is-active .hamburger-inner {
  transform: translate3d(0, 10px, 0) rotate(45deg); }

.hamburger--slider.is-active .hamburger-inner::before {
  transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
  opacity: 0; }

.hamburger--slider.is-active .hamburger-inner::after {
  transform: translate3d(0, -20px, 0) rotate(-90deg); }

/*
     * Slider Reverse
     */
.hamburger--slider-r .hamburger-inner {
  top: 2px; }

.hamburger--slider-r .hamburger-inner::before {
  top: 10px;
  transition-property: transform, opacity;
  transition-timing-function: ease;
  transition-duration: 0.15s; }

.hamburger--slider-r .hamburger-inner::after {
  top: 20px; }

.hamburger--slider-r.is-active .hamburger-inner {
  transform: translate3d(0, 10px, 0) rotate(-45deg); }

.hamburger--slider-r.is-active .hamburger-inner::before {
  transform: rotate(45deg) translate3d(5.71429px, -6px, 0);
  opacity: 0; }

.hamburger--slider-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -20px, 0) rotate(90deg); }

/*
     * Spin
     */
.hamburger--spin .hamburger-inner {
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spin .hamburger-inner::before {
  transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in; }

.hamburger--spin .hamburger-inner::after {
  transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spin.is-active .hamburger-inner {
  transform: rotate(225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }

.hamburger--spin.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out; }

.hamburger--spin.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
     * Spin Reverse
     */
.hamburger--spin-r .hamburger-inner {
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spin-r .hamburger-inner::before {
  transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in; }

.hamburger--spin-r .hamburger-inner::after {
  transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spin-r.is-active .hamburger-inner {
  transform: rotate(-225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }

.hamburger--spin-r.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out; }

.hamburger--spin-r.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(90deg);
  transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
     * Spring
     */
.hamburger--spring .hamburger-inner {
  top: 2px;
  transition: background-color 0s 0.13s linear; }

.hamburger--spring .hamburger-inner::before {
  top: 10px;
  transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spring .hamburger-inner::after {
  top: 20px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spring.is-active .hamburger-inner {
  transition-delay: 0.22s;
  background-color: transparent !important; }

.hamburger--spring.is-active .hamburger-inner::before {
  top: 0;
  transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translate3d(0, 10px, 0) rotate(45deg); }

.hamburger--spring.is-active .hamburger-inner::after {
  top: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translate3d(0, 10px, 0) rotate(-45deg); }

/*
     * Spring Reverse
     */
.hamburger--spring-r .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spring-r .hamburger-inner::after {
  top: -20px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0s linear; }

.hamburger--spring-r .hamburger-inner::before {
  transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spring-r.is-active .hamburger-inner {
  transform: translate3d(0, -10px, 0) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }

.hamburger--spring-r.is-active .hamburger-inner::after {
  top: 0;
  opacity: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0s 0.22s linear; }

.hamburger--spring-r.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(90deg);
  transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
     * Squeeze
     */
.hamburger--squeeze .hamburger-inner {
  transition-duration: 0.075s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--squeeze .hamburger-inner::before {
  transition: top 0.075s 0.12s ease, opacity 0.075s ease; }

.hamburger--squeeze .hamburger-inner::after {
  transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--squeeze.is-active .hamburger-inner {
  transform: rotate(45deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }

.hamburger--squeeze.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.075s ease, opacity 0.075s 0.12s ease; }

.hamburger--squeeze.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1); }

footer {
  background-color: #1E1E1C;
  padding-top: 60px;
  padding-bottom: 14px; }
  @media (max-width: 1199.98px) {
    footer .logo-footer {
      max-width: 255px;
      display: block;
      margin: auto;
      margin-bottom: 1rem; } }

.f-lista {
  list-style: none;
  padding: 0; }
  .f-lista li a {
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 24px;
    color: white;
    display: block; }
    @media (max-width: 575.98px) {
      .f-lista li a {
        line-height: 20px;
        margin: 8px 0; } }
  .f-lista li:first-child {
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 16px;
    color: #787A7C;
    margin-bottom: 0.8rem; }

.r-sociales a:not(:last-child) {
  margin-right: 8px; }

.r-sociales img {
  max-width: 21px;
  height: auto; }

@media (max-width: 575.98px) {
  .r-sociales {
    justify-content: center;
    display: flex;
    margin-bottom: 15px; } }

.derechos {
  color: #787A7C;
  font-size: 13px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 15px; }

.separador-footer {
  border: 1px solid #787a7c;
  opacity: .23;
  margin-top: 20px;
  margin-bottom: 20px; }

.credit-cards {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%; }
  @media (max-width: 575.98px) {
    .credit-cards {
      justify-content: center;
      margin-bottom: 15px; } }

.credit_card {
  width: 27px;
  height: 19px;
  background: url("../../../assets/img/logos/credictcard.png") no-repeat;
  display: inline-block;
  margin-left: 5px; }

credit_card.amazon {
  background-position: -123px -131px; }

.credit_card.amex {
  background-position: 0px 0px; }

.credit_card.apple {
  background-position: -81px -131px; }

.credit_card.c200 {
  background-position: -164px -98px; }

.credit_card.cirrus {
  background-position: -41px -33px; }

.credit_card.delta {
  background-position: -0px -65px; }

.credit_card.discover {
  background-position: -81px -33px; }

.credit_card.direct-debit {
  background-position: -41px -98px; }

.credit_card.google {
  background-position: -0px -98px; }

.credit_card.mastercard {
  background-position: -163px 0px; }

.credit_card.maestro {
  background-position: -0px -33px; }

.credit_card.moneybookers {
  background-position: -0px -131px; }

.credit_card.moneygram {
  background-position: -123px -98px; }

.credit_card.novus {
  background-position: -41px -131px; }

.credit_card.paypal-1 {
  background-position: -41px -65px; }

.credit_card.paypal-2 {
  background-position: -81px -65px; }

.credit_card.plain {
  background-position: -164px -131px; }

.credit_card.sage {
  background-position: -164px -65px; }

.credit_card.solo {
  background-position: -164px -33px; }

.credit_card.switch {
  background-position: -123px -33px; }

.credit_card.visa {
  background-position: -41px 1px; }

.credit_card.visa-debit {
  background-position: -81px 0px; }

.credit_card.visa-electron {
  background-position: -123px 0px; }

.credit_card.western-union {
  background-position: -82px -98px; }

.credit_card.worldpay {
  background-position: -123px -65px; }

.banner__cabecera {
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 438px; }
  @media (max-width: 991.98px) {
    .banner__cabecera {
      min-height: 300px; } }
  @media (max-width: 767.98px) {
    .banner__cabecera {
      min-height: 350px; } }
  .banner__cabecera .titulo {
    font-weight: 900;
    letter-spacing: 0;
    font-size: 80px;
    font-size: calc(32px + (80 - 32) * ((100vw - 360px) / (1600 - 360))); }
    @media screen and (max-width: 360px) {
      .banner__cabecera .titulo {
        font-size: 32px; } }
    @media screen and (min-width: 1600px) {
      .banner__cabecera .titulo {
        font-size: 80px; } }
  .banner__cabecera .f-destacada {
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 21px; }
  .banner__cabecera .enlace {
    text-decoration: underline;
    transition: all ease .3s; }
  .banner__cabecera__porque {
    background-image: url("../../../assets/img/por_que/cabecera-porque-alquilar.jpg"); }
    @media (max-width: 767.98px) {
      .banner__cabecera__porque {
        background-image: url("../../../assets/img/por_que/cabecera-porque-alquilar-rwd.jpg"); } }
  .banner__cabecera__faqs {
    background-image: url("../../../assets/img/faqs/cabecera-FAQs.jpg"); }
    @media (max-width: 767.98px) {
      .banner__cabecera__faqs {
        background-image: url("../../../assets/img/faqs/cabecera-FAQs-rwd.jpg"); } }

.banner__buscador__contenedor {
  background-image: url("../../../assets/img/home/cabecera-Alquiler-ret.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 438px; }
  @media (max-width: 767.98px) {
    .banner__buscador__contenedor {
      background-image: url("../../../assets/img/home/cabecera-alquiler.jpg");
      min-height: 426px; } }
  @media (max-width: 767.98px) {
    .banner__buscador__contenedor {
      min-height: 375px; } }
  .banner__buscador__contenedor .buscador {
    display: flex;
    align-items: center;
    cursor: pointer; }
    @media (max-width: 991.98px) {
      .banner__buscador__contenedor .buscador {
        max-width: 95%;
        margin: auto; } }
    .banner__buscador__contenedor .buscador input {
      width: calc(100% - 84px);
      box-shadow: inset 1px -1px 3px 0 rgba(0, 0, 0, 0.17);
      height: 50px;
      display: flex;
      border-radius: 100px 0 0 100px;
      padding: 10px 30px;
      border: none !important;
      font-size: 15px;
      font-weight: 400;
      letter-spacing: 0; }
      .banner__buscador__contenedor .buscador input::placeholder {
        color: #787A7C; }
      @media (min-width: 576px) {
        .banner__buscador__contenedor .buscador input {
          min-width: 470px; } }
      .banner__buscador__contenedor .buscador input:focus {
        box-shadow: inset 1px -1px 3px 0 rgba(0, 0, 0, 0.17); }
    .banner__buscador__contenedor .buscador i {
      box-shadow: inset 1px -1px 3px 0 rgba(0, 0, 0, 0.17);
      width: 84px;
      height: 50px;
      background-color: #E85746;
      border-radius: 0 100px 100px 0; }

.banner__cat-producto {
  background-image: url("../../../assets/img/general/cabecera-categoria-dumper.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 239px; }
  .banner__cat-producto > * {
    padding-left: 100px;
    padding-bottom: 35px; }

.t-bloque {
  font-family: "Public Sans";
  font-size: 44px;
  font-size: calc(24px + (44 - 24) * ((100vw - 360px) / (1600 - 360)));
  font-weight: bold;
  letter-spacing: 0;
  color: #1E1E1C; }
  @media screen and (max-width: 360px) {
    .t-bloque {
      font-size: 24px; } }
  @media screen and (min-width: 1600px) {
    .t-bloque {
      font-size: 44px; } }
  .t-bloque.white {
    color: white; }

.t-parrafos__parrafos {
  margin-top: 36px; }
  @media (max-width: 991.98px) {
    .t-parrafos__parrafos {
      margin-top: 24px; } }
  @media (max-width: 767.98px) {
    .t-parrafos__parrafos {
      margin-top: 18px; } }

.ventaja {
  margin-top: 50px; }
  @media (max-width: 991.98px) {
    .ventaja {
      margin-top: 32px; } }
  @media (max-width: 767.98px) {
    .ventaja {
      margin-top: 26px; } }
  .ventaja img {
    display: block;
    margin: auto; }
  .ventaja .t-ventaja {
    margin-top: 18px;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    text-align: center;
    display: block; }
  .ventaja .cuerpo {
    margin-top: 6px;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 25px;
    text-align: center;
    display: block;
    text-align: center; }

.banner-descaraga .contenedor {
  background-image: url("../../../assets/img/por_que/catalogo-mockup.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 555px;
  padding-top: 92px; }
  @media (max-width: 1600px) {
    .banner-descaraga .contenedor {
      min-height: 416px; } }
  @media (max-width: 1199.98px) {
    .banner-descaraga .contenedor {
      min-height: 346px;
      padding: 72px 0; } }
  @media (max-width: 991.98px) {
    .banner-descaraga .contenedor {
      min-height: 286px;
      padding-top: 52px 0; } }

.banner-descaraga .subtitulo {
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 21px; }

.faqs {
  background-color: #F5F6F7; }
  .faqs .bloque-faqs .card {
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.04); }
    .faqs .bloque-faqs .card:not(:last-child) {
      margin-bottom: 20px; }
    .faqs .bloque-faqs .card .pregunta h2 {
      font-size: 22px;
      font-size: calc(18px + (22 - 18) * ((100vw - 360px) / (1600 - 360)));
      font-weight: bold;
      letter-spacing: 0;
      line-height: 26px;
      text-align: left; }
      @media screen and (max-width: 360px) {
        .faqs .bloque-faqs .card .pregunta h2 {
          font-size: 18px; } }
      @media screen and (min-width: 1600px) {
        .faqs .bloque-faqs .card .pregunta h2 {
          font-size: 22px; } }
    .faqs .bloque-faqs .card .pregunta__contenedor {
      border-radius: 1px;
      background-color: #FFFFFF;
      padding: 28px; }
      .faqs .bloque-faqs .card .pregunta__contenedor .mas {
        position: relative;
        min-width: 18px;
        min-height: 18px;
        margin-right: 22px; }
        @media (max-width: 575.98px) {
          .faqs .bloque-faqs .card .pregunta__contenedor .mas {
            margin-right: 15px; } }
        .faqs .bloque-faqs .card .pregunta__contenedor .mas::after {
          content: "";
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 100%;
          height: 4px;
          background-color: #E85746; }
        .faqs .bloque-faqs .card .pregunta__contenedor .mas::before {
          content: "";
          position: absolute;
          left: 50%;
          top: 0;
          transform: translateX(-50%);
          width: 4px;
          height: 100%;
          background-color: #E85746;
          transition: all ease .3s; }
        .faqs .bloque-faqs .card .pregunta__contenedor .mas.cerrado::before {
          transform: rotate(90deg); }
    .faqs .bloque-faqs .card .card-body {
      padding-left: 28px;
      margin-left: 40px;
      color: #787A7C;
      font-size: 15px;
      letter-spacing: 0;
      line-height: 25px; }
      @media (max-width: 575.98px) {
        .faqs .bloque-faqs .card .card-body {
          margin-right: 33px; } }

.cat-faqs {
  list-style: none;
  padding: 0;
  margin-top: 1rem; }
  .cat-faqs li button {
    color: #1E1E1C;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0;
    padding: 10px 0;
    cursor: pointer;
    transition: all ease .3; }
    .cat-faqs li button.click {
      color: #E85746; }
  @media (max-width: 991.98px) {
    .cat-faqs {
      display: flex;
      flex-wrap: wrap; }
      .cat-faqs li:not(:last-child) {
        margin-right: 10px; } }
  @media (min-width: 992px) {
    .cat-faqs li:not(:last-child) {
      border-bottom: 1px solid #DDDEDE; } }

.productos-alquiler .textos {
  padding: 25px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #F5F6F7;
  transition: all ease 0.3s; }
  .productos-alquiler .textos span {
    font-size: 13px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 24px;
    color: #E85746;
    transition: all ease 0.3s; }
  .productos-alquiler .textos .cat {
    font-size: 18px;
    font-size: calc(17px + (18 - 17) * ((100vw - 360px) / (1600 - 360)));
    font-weight: 900;
    letter-spacing: 0;
    line-height: 21px;
    color: #1E1E1C; }
    @media screen and (max-width: 360px) {
      .productos-alquiler .textos .cat {
        font-size: 17px; } }
    @media screen and (min-width: 1600px) {
      .productos-alquiler .textos .cat {
        font-size: 18px; } }

.productos-alquiler .producto:hover .textos {
  background-color: #F3C300; }

.productos-alquiler .producto:hover span:not(.cat) {
  color: #1E1E1C; }

.slider-contenedor .slick-prev {
  left: 45px; }

.slider-contenedor .slick-next {
  right: 45px; }

.slider-contenedor .slick-arrow {
  z-index: 1; }
  .slider-contenedor .slick-arrow::before {
    font-size: 40px;
    color: #1E1E1C;
    opacity: 1; }

.slider-contenedor .slick-dots li button:before {
  font-size: 9px; }

.slider-contenedor .slick-dots li.slick-active button:before {
  color: #E85746; }

.slider-contenedor .img-contenedor {
  width: 100%;
  min-height: 317px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  outline: none !important; }
  @media (max-width: 991.98px) {
    .slider-contenedor .img-contenedor {
      min-height: 250px; } }
  @media (max-width: 767.98px) {
    .slider-contenedor .img-contenedor {
      min-height: 150px; } }

.separador {
  width: 100%;
  height: 1px;
  border: 1px solid #DDDEDE; }

.delegacion {
  background-color: #E85746;
  padding: 26px;
  min-height: 100%; }

.breadcrumb > li, .breadcrumb li * {
  color: #1E1E1C;
  font-family: "Public Sans";
  font-size: 15px;
  letter-spacing: 0;
  line-height: 17px; }

.breadcrumb .active {
  color: #E85746; }

.cont-categoria .productos {
  flex-wrap: wrap; }

.cont-categoria .producto {
  width: 24%;
  background-color: white;
  padding: 1rem;
  margin-bottom: 1rem; }
  @media (max-width: 991.98px) {
    .cont-categoria .producto {
      width: 48%; } }
  .cont-categoria .producto__titulo {
    font-size: 18px;
    font-size: calc(15px + (18 - 15) * ((100vw - 360px) / (1600 - 360)));
    font-weight: bold;
    letter-spacing: 0;
    line-height: 21px; }
    @media screen and (max-width: 360px) {
      .cont-categoria .producto__titulo {
        font-size: 15px; } }
    @media screen and (min-width: 1600px) {
      .cont-categoria .producto__titulo {
        font-size: 18px; } }
  .cont-categoria .producto .btn-producto {
    background-color: #E85746;
    display: block;
    text-align: center;
    color: white;
    width: 100%;
    padding: 8px 0; }

.cat-bg {
  background-color: #E8574688;
  padding: 0px 4px;
  color: #1E1E1C !important; }

.info .fecha {
  text-align: center;
  background-color: #1E1E1C;
  min-width: 67px;
  min-height: 64px;
  width: 67px;
  height: 64px;
  display: flex;
  flex-direction: column; }

.filtro .nav-tabs * {
  color: #1E1E1C !important; }

@media (max-width: 575.98px) {
  .filtro .nav-tabs:not(.producto__tab) li:first-child {
    width: 100%;
    margin-bottom: 12px; } }

@media (max-width: 575.98px) {
  .filtro > .col-12 {
    padding: 0; } }

.filtro .tab-content {
  background-color: #FFFFFF;
  padding: 24px;
  padding-bottom: 60px; }
  .filtro .tab-content .separador {
    height: 1px;
    border: 1px solid #F5F6F7;
    margin: 18px 0; }

.filtro__borrar {
  min-width: 170px;
  max-height: 30px;
  display: flex;
  align-items: center; }

.filtro .nav-item {
  margin-bottom: -5px; }
  .filtro .nav-item a {
    border: white; }

.cats-filtro__cat {
  border-radius: 1px;
  background-color: #FFFFFF;
  border: 0.4px solid #B5B5B5;
  padding: 6px 10px;
  display: inline-block;
  margin: 4px 6px;
  color: #1E1E1C;
  line-height: 12px; }
  .cats-filtro__cat.selected {
    background-color: #F5F6F7;
    border: 0;
    color: #787A7C; }

.paginacion * {
  color: #1E1E1C !important; }

.paginacion .disabled {
  color: #787A7C !important; }

.paginacion .active {
  color: #E85746 !important; }

.paginacion__lista {
  list-style: none;
  padding: 0;
  display: flex;
  margin: 0 130px; }
  @media (max-width: 575.98px) {
    .paginacion__lista {
      margin: auto; } }
  .paginacion__lista li {
    margin: 0 6px; }

.producto {
  border: 1px solid #F5F6F7; }
  .producto__texto * {
    color: #787A7C;
    font-size: 15px;
    font-size: calc(14px + (15 - 14) * ((100vw - 360px) / (1600 - 360)));
    letter-spacing: 0;
    line-height: 19px; }
    @media screen and (max-width: 360px) {
      .producto__texto * {
        font-size: 14px; } }
    @media screen and (min-width: 1600px) {
      .producto__texto * {
        font-size: 15px; } }
  .producto__texto p {
    opacity: 1;
    transform: scaleY(1);
    transform-origin: top;
    transition: all ease .3s;
    height: auto; }
    .producto__texto p.hide {
      opacity: 0;
      transform: scaleY(0);
      height: 0; }
  .producto__tab > li a {
    transition: all ease .3s; }
    .producto__tab > li a.active {
      background-color: #F5F6F7 !important; }
    @media (max-width: 767.98px) {
      .producto__tab > li a {
        padding: 0.5rem 0.8rem; } }
  .producto__tab + .tab-content {
    background-color: #F5F6F7 !important; }

.modelo {
  padding: 30px;
  display: none; }
  @media (max-width: 575.98px) {
    .modelo {
      padding: 1rem; } }
  .modelo.active {
    display: block; }
  .modelo__info {
    border: 1px solid #B5B5B5; }
    .modelo__info img {
      max-width: 153px; }
  .modelo__lista {
    max-width: 520px;
    max-width: 100%; }
    .modelo__lista ul {
      padding-left: 20px; }
    .modelo__lista * {
      font-family: 'Public Sans', sans-serif; }
  .modelo__disparador button {
    color: #787A7C !important;
    transition: all ease .3s; }
    @media (max-width: 991.98px) {
      .modelo__disparador button {
        margin: 1rem 0.5rem;
        margin-left: 0; } }
    @media (max-width: 575.98px) {
      .modelo__disparador button {
        margin: 0.5rem;
        margin-left: 0; } }
    .modelo__disparador button.active {
      color: #E85746 !important; }
    @media (min-width: 992px) {
      .modelo__disparador button:not(:last-child)::after {
        content: "";
        width: 100%;
        display: block;
        border: 1px solid #DDDEDE;
        margin: 24px 0; } }

.modal__form .modal-header {
  background-color: #1E1E1C; }

.modal__form .modal-title {
  color: #FFFFFF; }

.modal__form label {
  font-family: 'Public Sans', sans-serif; }

.modal__form input:not([type='radio']):not([type='checkbox']) {
  font-family: 'Public Sans', sans-serif;
  box-shadow: none !important;
  border: 1px solid #DDDEDE !important;
  background-color: #FFFFFF;
  color: #B5B5B5;
  font-size: 13px;
  font-size: calc(12px + (13 - 12) * ((100vw - 360px) / (1600 - 360)));
  letter-spacing: 0;
  height: 44px;
  padding: 0 20px; }
  @media screen and (max-width: 360px) {
    .modal__form input:not([type='radio']):not([type='checkbox']) {
      font-size: 12px; } }
  @media screen and (min-width: 1600px) {
    .modal__form input:not([type='radio']):not([type='checkbox']) {
      font-size: 13px; } }

.modal__form input[type=radio] {
  position: relative;
  visibility: hidden; }
  .modal__form input[type=radio]:checked::after {
    visibility: visible;
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 6px;
    height: 6px;
    background-color: #E85746;
    border-radius: 50px;
    display: block; }
  .modal__form input[type=radio]::before {
    visibility: visible;
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 15px;
    height: 15px;
    border: 2px solid #787A7C;
    background-color: transparent;
    border-radius: 50px;
    display: block; }

.modal__form input[type=checkbox] {
  position: relative;
  visibility: hidden; }
  .modal__form input[type=checkbox]:checked::after {
    visibility: visible;
    content: "";
    position: absolute;
    left: 9px;
    top: 30%;
    transform: translate(-50%, -50%) rotate(-45deg);
    width: 12px;
    height: 7px;
    border-bottom: 4px solid #E85746;
    border-left: 4px solid #E85746;
    display: block; }
  .modal__form input[type=checkbox]::before {
    visibility: visible;
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 18px;
    height: 18px;
    border: 2px solid #787A7C;
    background-color: transparent;
    display: block; }

.modal__form select {
  width: 100%;
  display: block;
  font-family: 'Public Sans', sans-serif;
  box-shadow: none !important;
  border: 1px solid #DDDEDE !important;
  background-color: #FFFFFF;
  color: #B5B5B5;
  font-size: 13px;
  font-size: calc(12px + (13 - 12) * ((100vw - 360px) / (1600 - 360)));
  letter-spacing: 0;
  height: 44px;
  padding: 0 20px;
  appearance: none;
  outline: none;
  position: relative; }
  @media screen and (max-width: 360px) {
    .modal__form select {
      font-size: 12px; } }
  @media screen and (min-width: 1600px) {
    .modal__form select {
      font-size: 13px; } }

.modal__form__separador {
  border: 0.5px solid #B5B5B5;
  width: 100%; }

.modal__form .select-parent {
  position: relative; }
  .modal__form .select-parent i {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%); }

@media (min-width: 992px) {
  #megamenu {
    justify-content: center; } }

@media (min-width: 992px) {
  #megamenu .navbar-nav > li:not(:last-child) {
    margin-right: 50px; } }

.navbar-nav * {
  color: #1E1E1C; }

.navbar-nav ul {
  list-style: none;
  padding: 0; }

.navbar-nav a {
  transition: all ease .3s; }
  .navbar-nav a i {
    transition: all ease .3s; }
  .navbar-nav a:hover {
    color: #E85746; }
    .navbar-nav a:hover > i {
      color: #E85746; }

.navbar-nav .menu_container {
  display: flex;
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  top: 60px;
  background-color: #F5F6F7;
  min-height: 500px;
  align-items: center;
  transform: scaleY(0);
  transform-origin: top;
  transition: all ease .3s; }
  .navbar-nav .menu_container .container {
    justify-content: start;
    transform: scale(0);
    transition: all ease .3s; }
  .navbar-nav .menu_container.open {
    transform: scaleY(1); }
    .navbar-nav .menu_container.open > .container {
      transform: scale(1); }

.navbar-nav .menu_lv_2::before {
  position: absolute;
  right: 0;
  bottom: 0;
  content: "";
  height: 100%;
  max-height: 248px;
  width: 2px;
  background-color: #DDDEDE; }

.navbar-nav .menu_lv_2 > li {
  cursor: pointer;
  padding-right: 86px;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 46px; }
  .navbar-nav .menu_lv_2 > li:first-child {
    display: flex;
    align-items: baseline; }
  .navbar-nav .menu_lv_2 > li:first-child span {
    font-size: 28px;
    font-size: calc(24px + (28 - 24) * ((100vw - 360px) / (1600 - 360)));
    font-weight: bold;
    letter-spacing: 0;
    line-height: 33px;
    margin-right: 22px; }
    @media screen and (max-width: 360px) {
      .navbar-nav .menu_lv_2 > li:first-child span {
        font-size: 24px; } }
    @media screen and (min-width: 1600px) {
      .navbar-nav .menu_lv_2 > li:first-child span {
        font-size: 28px; } }
  .navbar-nav .menu_lv_2 > li:first-child a {
    font-size: 15px;
    letter-spacing: 0;
    line-height: 17px; }
  .navbar-nav .menu_lv_2 > li span {
    transition: all ease .3s;
    display: flex;
    align-items: center; }
    .navbar-nav .menu_lv_2 > li span i {
      margin-left: 8px;
      font-size: 12px;
      color: #787A7C;
      transition: all ease .3s; }
    .navbar-nav .menu_lv_2 > li span:hover {
      color: #E85746; }
      .navbar-nav .menu_lv_2 > li span:hover i {
        font-size: 16px;
        color: #1E1E1C; }

.navbar-nav .menu_lv_3 {
  display: flex;
  z-index: -1;
  position: absolute;
  right: -660px;
  top: 50%;
  transform: translateY(-50%);
  width: 500px;
  flex-wrap: wrap;
  opacity: 0;
  transition: opacity ease-in .3s; }
  @media (max-width: 1199.98px) {
    .navbar-nav .menu_lv_3 {
      right: -560px; } }
  .navbar-nav .menu_lv_3.open {
    z-index: 1;
    opacity: 1; }
  .navbar-nav .menu_lv_3 li {
    width: 50%;
    margin: 0;
    margin-bottom: 0.6rem; }
    .navbar-nav .menu_lv_3 li a {
      font-size: 16px;
      line-height: 31px;
      display: block;
      letter-spacing: 0;
      font-weight: 400;
      height: 100%; }
  .navbar-nav .menu_lv_3__space_3 li {
    width: 33%; }

.navbar-nav .fa-arrow-right {
  font-size: 12px; }

.faqs-menu a {
  font-size: 15px;
  letter-spacing: 0;
  line-height: 17px;
  font-weight: 400; }

.navbar-toggler {
  border: none; }
  .navbar-toggler:focus {
    outline: none; }

.c-logo-rwd {
  max-width: 70%; }

.navbar-rwd {
  list-style: none;
  padding: 0; }
  .navbar-rwd li > * {
    font-size: 22px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 61px; }

.c-a-div a {
  font-size: 16px;
  letter-spacing: 0;
  line-height: 19px;
  color: #1E1E1C;
  display: block; }
  .c-a-div a:nth-child(2) {
    margin-top: 18px; }

.close-megamenu {
  display: block;
  margin-bottom: auto;
  POSITION: relative;
  right: 100px;
  font-size: 34px;
  top: 40px; }
  .close-megamenu i {
    color: #1E1E1C !important; }

@media (max-width: 991.98px) {
  .navbar {
    background-color: white;
    padding: 0; }
    .navbar.open {
      position: fixed;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 1; }
  .c-logo-rwd, .navbar-toggler {
    padding: 1rem; }
  .navbar-toggler {
    padding-left: 0; }
  .c-logo-rwd {
    padding-right: 0; }
  .sticky-nav {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    animation-name: scrolled-in;
    animation-duration: 1s; }
    .sticky-nav.out {
      top: -100px;
      animation-name: scrolled-out;
      animation-duration: .5s; }
  @keyframes scrolled-in {
    from {
      top: -100px; }
    to {
      top: 0; } }
  @keyframes scrolled-out {
    from {
      top: 0px; }
    to {
      top: -100; } }
  #megamenu-rwd {
    width: 100%;
    padding-left: 34px; }
    #megamenu-rwd ul {
      list-style: none; }
    #megamenu-rwd *:not(.fa-chevron-left) {
      color: #1E1E1C; }
    #megamenu-rwd.show, #megamenu-rwd.collapsing, #megamenu-rwd .menu_lv_2, #megamenu-rwd .menu_lv_3 {
      min-height: calc(100vh - 90px);
      display: flex;
      flex-direction: column; }
    #megamenu-rwd i {
      font-size: 14px;
      margin-left: auto;
      margin-right: 20px; }
    #megamenu-rwd .menu_lv_2 {
      position: fixed;
      top: 92px;
      left: 100%;
      right: 0;
      z-index: 20;
      background-color: #F5F6F7;
      opacity: 0;
      transition: all ease .3s; }
      #megamenu-rwd .menu_lv_2 > li:not(:first-child) > a, #megamenu-rwd .menu_lv_2 > li:not(:first-child) > span {
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0;
        display: flex;
        align-items: center; }
        #megamenu-rwd .menu_lv_2 > li:not(:first-child) > a > .fa-chevron-right, #megamenu-rwd .menu_lv_2 > li:not(:first-child) > span > .fa-chevron-right {
          margin-left: auto; }
      #megamenu-rwd .menu_lv_2 > li:first-child {
        padding-bottom: 1rem;
        position: relative; }
        #megamenu-rwd .menu_lv_2 > li:first-child::after {
          content: "";
          position: absolute;
          width: 100%;
          height: 2px;
          background-color: #E8EBED;
          left: -40px;
          right: 0;
          bottom: 0;
          width: calc(100% + 40px); }
        #megamenu-rwd .menu_lv_2 > li:first-child .volver {
          font-size: 16px;
          letter-spacing: 0;
          line-height: 19px;
          font-weight: 400;
          color: #787A7C; }
      #megamenu-rwd .menu_lv_2 > li:nth-child(2) > span, #megamenu-rwd .menu_lv_2 > li:nth-child(2) > a {
        font-size: 22px;
        font-weight: bold;
        letter-spacing: 0;
        color: #787A7C; }
      #megamenu-rwd .menu_lv_2.open {
        left: 0;
        opacity: 1; } }
    @media (max-width: 991.98px) and (max-width: 578px) and (max-height: 720px) {
      #megamenu-rwd .menu_lv_2 {
        overflow-y: scroll;
        bottom: 0; } }

@media (max-width: 991.98px) {
    #megamenu-rwd .menu_lv_3 {
      position: fixed;
      top: 92px;
      left: 100%;
      right: 0;
      z-index: 20;
      background-color: #F5F6F7;
      opacity: 0;
      transition: all ease .3s; }
      #megamenu-rwd .menu_lv_3 > li:not(:first-child) > a, #megamenu-rwd .menu_lv_3 > li:not(:first-child) > span {
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0;
        display: flex;
        align-items: center; }
        #megamenu-rwd .menu_lv_3 > li:not(:first-child) > a > .fa-chevron-right, #megamenu-rwd .menu_lv_3 > li:not(:first-child) > span > .fa-chevron-right {
          margin-left: auto; }
      #megamenu-rwd .menu_lv_3 > li:first-child {
        padding-bottom: 1rem;
        position: relative; }
        #megamenu-rwd .menu_lv_3 > li:first-child::after {
          content: "";
          position: absolute;
          width: 100%;
          height: 2px;
          background-color: #E8EBED;
          left: -40px;
          right: 0;
          bottom: 0;
          width: calc(100% + 40px); }
        #megamenu-rwd .menu_lv_3 > li:first-child .volver {
          font-size: 16px;
          letter-spacing: 0;
          line-height: 19px;
          font-weight: 400;
          color: #787A7C; }
      #megamenu-rwd .menu_lv_3.open {
        left: 0;
        opacity: 1;
        bottom: 0;
        overflow-y: scroll; } }
    @media (max-width: 991.98px) and (max-width: 578px) and (max-height: 720px) {
      #megamenu-rwd .menu_lv_3 {
        overflow-y: scroll;
        bottom: 0; } }

@media (max-width: 991.98px) {
    #megamenu-rwd .volver {
      margin-top: 44px;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 19px;
      color: #787A7C;
      border-bottom: 1.5px solid #F5F6F7; }
    #megamenu-rwd .c-a-div {
      margin-top: auto;
      margin-bottom: 50px; } }

.no-overflow {
  overflow: hidden; }
