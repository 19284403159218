$colors: (

    "red": #E85746,
    "red-opacy": #E8574688,
    "black": #1E1E1C,
    "grey": #787A7C,
    "grey-bg": #F5F6F7,
    "pink": #F9E0DD,
    "white": #FFFFFF,
    "white_1": #F5F6F7,
    "yellow": #dd9933,
    "yellow-hover": #F3C300


);

@function color($color-name) {
    @return map-get($colors, $color-name);
}

@each $colorNameKey, $colorHex in $colors {
    .color-#{$colorNameKey} {
        color: $colorHex;
    }

    .bg-#{$colorNameKey} {
        background-color: $colorHex;
    }
}