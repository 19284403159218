.t-bloque {
    font-family: "Public Sans";
    @include fluid-font(24px, 44px);
    font-weight: bold;
    letter-spacing: 0;
    color: color("black");

    &.white{
        color: white;
    }
}

.t-parrafos {

    &__parrafos {
        margin-top: 36px;

        @include media-breakpoint-down(md) {
            margin-top: 24px;
        }

        @include media-breakpoint-down(sm) {
            margin-top: 18px;
        }
    }
}

.ventaja {
    margin-top: 50px;

    @include media-breakpoint-down(md) {
        margin-top: 32px;
    }

    @include media-breakpoint-down(sm) {
        margin-top: 26px;
    }

    img {
        display: block;
        margin: auto;
    }

    .t-ventaja {
        margin-top: 18px;
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 0;
        text-align: center;
        display: block;
    }

    .cuerpo {
        margin-top: 6px;
        font-size: 15px;
        letter-spacing: 0;
        line-height: 25px;
        text-align: center;
        display: block;
        text-align: center;
    }
}

.banner-descaraga {
    .contenedor {

        background-image: url("../../../assets/img/por_que/catalogo-mockup.jpg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        min-height: 555px;
        padding-top: 92px;

        @media(max-width: 1600px) {
            min-height: 416px;
        }

        @include media-breakpoint-down(lg) {
            min-height: 346px;
            padding: 72px 0;
        }

        @include media-breakpoint-down(md) {
            min-height: 286px;
            padding-top: 52px 0;
        }
    }

    .subtitulo {
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 21px;
    }

}

.faqs {
    background-color: #F5F6F7;

    .bloque-faqs {
        .card {
            box-shadow: 0 3px 4px 0 rgba(0,0,0,0.04);
            
            &:not(:last-child) {

                margin-bottom: 20px;

            }
            
            .pregunta {
                h2{
                    @include fluid-font(18px, 22px);
                    font-weight: bold;
                    letter-spacing: 0;
                    line-height: 26px;
                    text-align: left;
                }

                &__contenedor {
                    border-radius: 1px;
                    background-color: #FFFFFF;
                    padding: 28px;

                    .mas {
                        position: relative;
                        min-width: 18px;
                        min-height: 18px;
                        margin-right: 22px;

                        @include media-breakpoint-down(xs) {
                            margin-right: 15px;
                        }

                        &::after {
                            content: "";
                            position: absolute;
                            left: 0;
                            top: 50%;
                            transform: translateY(-50%);
                            width: 100%;
                            height: 4px;
                            background-color: color("red");
                        }

                        &::before {
                            content: "";
                            position: absolute;
                            left: 50%;
                            top: 0;
                            transform: translateX(-50%);
                            width: 4px;
                            height: 100%;
                            background-color: color("red");
                            transition: all ease .3s;
                        }

                        &.cerrado {
                            &::before {
                                transform: rotate(90deg);
                            }
                        }
                    }
                }

            }

            .card-body{
                padding-left: 28px;
                margin-left: 40px;
                color: color("grey");
                font-size: 15px;
                letter-spacing: 0;
                line-height: 25px;
                @include media-breakpoint-down(xs) {
                    margin-right: 33px;
                }
            }
        }

    }
}

.cat-faqs{
    list-style: none;
    padding: 0;
    margin-top: 1rem;

    li{
        button{
            color: color("black");
            font-size: 15px;
            font-weight: bold;
            letter-spacing: 0;
            padding: 10px 0;
            cursor: pointer;
            transition: all ease .3;

            &.click{
                color: color("red");
            }
        }
    }

    @include media-breakpoint-down(md) { 
        display: flex;
        flex-wrap: wrap;

        li:not(:last-child){
            margin-right: 10px;
        }
    }

    @include media-breakpoint-up(lg) { 
        li:not(:last-child){
            border-bottom: 1px solid #DDDEDE;
        }
    }
    
}

.productos-alquiler{

    .textos{
        padding: 25px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: color("grey-bg");
        transition: all ease 0.3s;

        span{
            font-size: 13px;
            font-weight: 300;
            letter-spacing: 0;
            line-height: 24px;
            color: color("red");
            transition: all ease 0.3s; 
        }
        .cat{
            @include fluid-font(17px, 18px);
            font-weight: 900;
            letter-spacing: 0;
            line-height: 21px;
            color: color("black");
        }
    }

    .producto{
        &:hover{
            .textos{
                background-color: color("yellow-hover");
            }

            span:not(.cat){
                color: color("black");
            }
        }
    }
}

.slider-contenedor{
    .slick-prev{
        left: 45px;
    } 
    .slick-next{
        right: 45px;
    } 
    
    .slick-arrow{
        z-index: 1;
        &::before{
            font-size: 40px;
            color: color("black");
            opacity: 1;
        }
    }
    .slick-dots li button:before{
        font-size: 9px;
    }
    .slick-dots li.slick-active button:before{
        color: color("red");
    }

    .img-contenedor{
        width: 100%;
        min-height: 317px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        outline: none !important;

        @include media-breakpoint-down(md) { 
            min-height: 250px;
        }
        @include media-breakpoint-down(sm) { 
            min-height: 150px;
        }
            
    }
}

.separador{
    width: 100%;
    height: 1px;
    border: 1px solid #DDDEDE;
}

.delegacion{

    background-color: color("red");
    padding: 26px;
    min-height: 100%;
}  

.breadcrumb{
    > li, li *{
        color: color("black");
        font-family: "Public Sans";
        font-size: 15px;
        letter-spacing: 0;
        line-height: 17px;
    }
    .active{
        color: color("red");
    }
}

.cont-categoria{
    .productos{
        flex-wrap: wrap;
    }

    .producto{
        width: 24%;
        background-color: white;
        padding: 1rem;
        margin-bottom: 1rem;
        @include media-breakpoint-down(md) { 
            width: 48%;
        }
        &__titulo{
            @include fluid-font(15px, 18px);
            font-weight: bold;
            letter-spacing: 0;
            line-height: 21px;
        }
        .btn-producto{
            background-color: color("red");
            display: block;
            text-align: center;
            color: white;
            width: 100%;
            padding: 8px 0;
        }
    }
}

.cat-bg{
    background-color: color("red-opacy");
    padding: 0px 4px;
    color: color("black") !important;
} 

.info{
    
    .fecha{
        text-align: center;
        background-color: color("black");
        min-width: 67px;
        min-height: 64px;
        width: 67px;
        height: 64px;
        display: flex;
        flex-direction: column;
    }
}

.filtro{
    .nav-tabs{
        *{
            color: color("black") !important; 
        }

        &:not(.producto__tab){
            @include media-breakpoint-down(xs) { 
                li:first-child{
                    width: 100%;
                    margin-bottom: 12px;
                }
            }
        }
       
    }
    @include media-breakpoint-down(xs) { 
        > .col-12{
            padding: 0;
        }
    }
    .tab-content{
        background-color: color("white");
        padding: 24px;
        padding-bottom: 60px;

        .separador{
            height: 1px;
            border: 1px solid color("grey-bg");
            margin: 18px 0;
        }
    }

    &__borrar{
        min-width: 170px;
        max-height: 30px;
        display: flex;
        align-items: center;
    }
    .nav-item{
        margin-bottom: -5px;

        a{
            border: white;
        }
    }
}
.cats-filtro{
    &__cat{
        border-radius: 1px;
        background-color: color("white");
        border: 0.4px solid #B5B5B5;
        padding: 6px 10px;
        display: inline-block;
        margin: 4px 6px;
        color: color("black");
        line-height: 12px;

        &.selected{
            background-color: color("white_1");
            border: 0;
            color: color("grey");
        }
    }
}
.paginacion{
    *{
        color: color("black") !important;
    }

    .disabled{
        color: color("grey") !important;
    }

    .active{
        color: color("red") !important;
    }

    &__lista{
        list-style: none;
        padding: 0;
        display: flex;
        margin: 0 130px;
        @include media-breakpoint-down(xs) { 
            margin: auto;
        }
        li {
            margin: 0 6px;
        }
    }
}

.producto{
    border: 1px solid color("grey-bg");

    &__texto{
        *{
            color: color("grey");
            @include fluid-font(14px, 15px);
            letter-spacing: 0;
            line-height: 19px;
        }

        p{
            opacity: 1;
            transform: scaleY(1);
            transform-origin: top;
            transition: all ease .3s;
            height: auto;
            &.hide{
                opacity: 0;
                transform: scaleY(0);
                height: 0;
            }
        }
    }

    &__tab{
        > li {
            a{
                transition: all ease .3s;
                &.active{
                    background-color: color("grey-bg") !important;
                } 

                @include media-breakpoint-down(sm) { 
                    padding: 0.5rem 0.8rem;
                }
            }
        }

        + .tab-content{
            background-color: color("grey-bg") !important;
        }
    }

}

.modelo{
    padding: 30px;
    display: none;

    @include media-breakpoint-down(xs) {
        padding: 1rem;
    }

    &.active{
        display: block;
    }
    &__info{
        border: 1px solid #B5B5B5;
        img{
            max-width: 153px;
        }
    }

    &__lista{
        max-width: 520px;

        ul{
            padding-left: 20px;
        }

        max-width: 100%;
        

        *{
            font-family: 'Public Sans', sans-serif;
        }
    }

    &__disparador{
        button{
            color: color("grey") !important;
            transition: all ease .3s;
            @include media-breakpoint-down(md) {
                margin: 1rem 0.5rem;
                margin-left: 0;
            }
            @include media-breakpoint-down(xs) {
                margin: 0.5rem;
                margin-left: 0;
            }
            &.active{
                color: color("red") !important;
            }

            @include media-breakpoint-up(lg) { 
                &:not(:last-child){
                    &::after{
                        content: "";
                        width: 100%;
                        display: block;
                        border: 1px solid #DDDEDE;
                        margin: 24px 0;
    
                        
                    }
                }
            }

        }
    }
}

.modal{

    &__form{
        .modal-header{
            background-color: color("black");
        }
        .modal-title{
            color: color("white");
        }

        label{
            font-family: 'Public Sans', sans-serif;
        }

        input{
            
            &:not([type='radio']):not([type='checkbox']){
                font-family: 'Public Sans', sans-serif;
                box-shadow: none !important;
                border: 1px solid #DDDEDE !important;
                background-color: #FFFFFF;
                color: #B5B5B5;
                @include fluid-font(12px, 13px);
                letter-spacing: 0;
                height: 44px;
                padding: 0 20px;
            }
            &[type=radio]{
                position: relative;
                visibility: hidden;

                &:checked{
                    &::after{
                        visibility: visible;
                        content:"";
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        width: 6px;
                        height: 6px;
                        background-color: color("red");
                        border-radius: 50px;
                        display: block;
                    }

                }
                &::before{
                    visibility: visible;
                    content:"";
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    width: 15px;
                    height: 15px;
                    border: 2px solid color("grey");
                    background-color: transparent;
                    border-radius: 50px;
                    display: block;
                }
                
            }

            &[type=checkbox]{
                position: relative;
                visibility: hidden;

                &:checked{
                    &::after{
                        visibility: visible;
                        content:"";
                        position: absolute;
                        left: 9px;
                        top: 30%;
                        transform: translate(-50%,-50%) rotate(-45deg);
                        width: 12px;
                        height: 7px;
                        border-bottom: 4px solid color("red");
                        border-left: 4px solid color("red");
                        display: block;
                    }

                }
                &::before{
                    visibility: visible;
                    content:"";
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 18px;
                    height: 18px;
                    border: 2px solid color("grey");
                    background-color: transparent;
                    display: block;
                }
            }
        }

        select{
            width: 100%;
            display: block;
            font-family: 'Public Sans', sans-serif;
            box-shadow: none !important;
            border: 1px solid #DDDEDE !important;
            background-color: #FFFFFF;
            color: #B5B5B5;
            @include fluid-font(12px, 13px);
            letter-spacing: 0;
            height: 44px;
            padding: 0 20px;
            appearance:none;
            outline: none;
            position: relative;
        }

        &__separador{
            border: 0.5px solid #B5B5B5;
            width: 100%;
        }

        .select-parent{
            position: relative;
            i{
                position: absolute;
                top: 50%;
                right: 10px;
                transform: translateY(-50%);
            }
        }
    }
}