.top-bar{
    &__content{
        min-height: 45px;

        > div{
            &:first-child{
                margin-right: 30px;
            }

            @include media-breakpoint-down(xs) {
                margin: 10px 0;
            } 
        }
    }
    i {
        font-size: 20px;
        line-height: 1em;
        color: color("white");
        margin-right: 10px;
    }
    a{
        font-family: "Public Sans";
        font-size: 13px;
        letter-spacing: 0;
        line-height: 15px;
        transition: all ease .3s;
        color: color("white");

        &:hover{
            color: color("yellow");
        }
    }
}

.container-logo{ 
    padding: 60px 0; 
} 
.navbar{ 
    border-top: 1px solid color("white_1");
}

@include media-breakpoint-up(lg) {

    .navbar-nav{
        > li > *{
            font-size: 15px;
            letter-spacing: 0;
            line-height: 19px;
            font-weight: bold;
        } 
        .fa-chevron-down{
            font-size: 12px;
            transition: all ease .3s;
        }

        > li {
            &.ml-70{
                margin-left: 70px;
            }
        }
        button{
            transition: all ease .3s;
        }
        button:hover{
            color: color("red");
            i{
                color: color("red");
            }
        }
    } 

    .buscador-nav{
        cursor: pointer;
        width: 46px;
        height: 46px;

        .fa-search::after{
            content: "";
            position: absolute;
            z-index: -1;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 46px;
            height: 46px;
            border-radius: 50px;
            background-color: color("grey-bg");
        }

    }

    .buscador-container{
        background-color: white;
        position: absolute;
        left: 0;
        right: 0;
        top: 60px;
        z-index: 2;
        width: 100%;
        padding-top: 40px;
        padding-bottom: 80px;
        transform: scaleY(0);    
        transform-origin: top;
        transition: transform 0.26s ease;
        .btn{
            background-color: color("red");
            width: 82px;
            height: 67px;
            i{
                color: white !important;
                font-size: 28px;
            }
            
        }

        .buscador{
            opacity: 0;
            transform: scale(0);
            transition: all ease 0.3s;
        }

        input{
            color: #B5B5B5 !important;
            font-family: "Public Sans";
            font-size: 26px;
            font-weight: bold;
            letter-spacing: -0.3px;
            line-height: 31px;
            padding: 17px 34px;
            border: 1px solid #1E1E1C !important;
            outline: none !important;
            
        }

        &.open{
            transform:scaleY(1);

            .buscador{
                opacity: 1;
                transform: scale(1);
            }
        }

    }

    .lupa{
        width: 100%;
        height: 100%;
    }

    .container-cabecera{
        z-index: 10;
        transition: all ease .3s;

        &.sticky{
            position: fixed;
            left: 0;
            right: 0;
            top: 0;
            background-color: white;
            animation-name: scrolled-in-d;
            animation-duration: 1s;
        }

        &.out{
            top: -256px;
            animation-name: scrolled-out-d;
            animation-duration: .5s;
        }

        @keyframes scrolled-in-d {
            from { top: -256px;}
            to { top:0;}
        }
    
        @keyframes scrolled-out-d {
            from { top: 0px;}
            to { top:-256px;}
        }
    }
} 

.pt-256{
    padding-top: 256px;
}