$font_path: '/assets/fonts/';

@font-face {
    font-family: 'Public Sans';
    src: url($font_path + 'public_sans/static/PublicSans-Regular.ttf') format('truetype');
    font-style: normal;
    font-weight: 400;
}
@font-face {
  font-family: 'Public Sans';
  src: url($font_path + 'public_sans/static/PublicSans-Bold.ttf') format('truetype');
  font-style: normal;
  font-weight: 700;
}
@font-face {
    font-family: 'Public Sans';
    src: url($font_path + 'public_sans/static/PublicSans-Black.ttf') format('truetype');
    font-style: normal;
    font-weight: 900;
}


@mixin fluid-font($min, $max, $min-vw: 360px, $max-vw: 1600px, $fallback: false) {
    $min-no-unit : strip-units($min);
    $max-no-unit : strip-units($max);
    
    $min-vw-no-unit : strip-units($min-vw);
    $max-vw-no-unit : strip-units($max-vw);
    
    @if getUnit($min) == "rem" or getUnit($min) == "em" {
      $min-no-unit : strip-units($min) * 16;
      $max-no-unit : strip-units($max) * 16;
    }
    
     $responsive : calc(#{$min-no-unit}px + (#{$max-no-unit} - #{$min-no-unit}) * ((100vw - #{$min-vw}) / (#{$max-vw-no-unit} - #{$min-vw-no-unit})));
    
    @if $fallback { font-size: $fallback; }
    @else { font-size: $max; }
  font-size:  $responsive;
  @media screen and (max-width: #{$min-vw}) { font-size: $min; }
     @media screen and (min-width: #{$max-vw}) { font-size: $max; }
  }
  @function strip-units($number) {
    @return $number / ($number * 0 + 1);
  }
  @function getUnit($value) {
    @return str-slice($value * 0 + "", 2, -1);
  }
  

.public-sans{
    font-family: 'Public Sans', sans-serif;
}


h1,h2,h3,p,span, a{
    font-family: 'Public Sans', sans-serif;
}

p{
  color: color("black");
  font-size: 15px;
  letter-spacing: 0;
  line-height: 25px;
}


.f-13{
  @include fluid-font(12px, 13px);
  letter-spacing: 0;
  line-height: 17px;
}
.f-15{
  @include fluid-font(14px, 15px);
  letter-spacing: 0;
  line-height: 19px;
}

.f-16{
  @include fluid-font(15px, 16px);
  letter-spacing: 0;
  line-height: 19px;
}

.f-18{
  @include fluid-font(17px, 18px);
  letter-spacing: 0;
  line-height: 21px;
}

.f-22{
  @include fluid-font(18px, 22px);
  letter-spacing: 0;
  line-height: 26px;
}

.f-28{
  @include fluid-font(22px, 28px);
  letter-spacing: 0;
  line-height: 35px;
}
.fw-bold{
  font-weight: bold;
}

.fw-900{
  font-weight: 900 !important;
}