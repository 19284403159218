h1 {
    margin: 0;
}
.btn:focus {
    outline: none;
    box-shadow: none;
  }
a {
    color: color("red");
    transition: all ease .3s;
    
    &:hover {
        text-decoration: none;
        color: color("yellow");
    }
}


.btn {

    cursor: pointer;

    &:focus {
        box-shadow: none;
    }

    &__descaraga {
        background-color: color("red");
        color: white;
        padding: 18px 42px;
        text-align: center;
        display: inline-block;
        font-size: 15px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 17px;
        transition: background-color ease .3;
        &:hover {
            color: white;
            background-color: color("yellow"); 
        }
    } 

    &__primario{
        background-color: color("red");
        width: 250px;
        padding: 1rem 0;
        color: white !important;
        border-radius: 0;
    }

    &__leer{
        color: color("black") !important;
        text-decoration: underline !important;
    }

    &__submit{
        background-color: color("red");
        width: 165px;
        padding: 1rem 0;
        color: #FFFFFF !important;
        font-family: "Public Sans";
        font-size: 15px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 17px;
        text-align: center;
        margin-top: 1rem;
        border-radius: 0 !important;
    }
}

.mt-70 {
    margin-top: 70px;

    @include media-breakpoint-down(md) {
        margin-top: 50px;
    }

    @include media-breakpoint-down(sm) {
        margin-top: 32px;
    }
}

.mt-50 {
    margin-top: 50px;

    @include media-breakpoint-down(md) {
        margin-top: 32px;
    }

    @include media-breakpoint-down(sm) {
        margin-top: 26px;
    }
}

.mt-40 {
    margin-top: 40px;

    @include media-breakpoint-down(md) {
        margin-top: 26px;
    }

    @include media-breakpoint-down(sm) {
        margin-top: 22px;
    }
}

.my-40{
    
    margin: 40px 0;

    @include media-breakpoint-down(md) {
        margin: 26px 0;
    }

    @include media-breakpoint-down(sm) {
        margin: 22px 0;
    }
    
}

.py-80 {
    padding-top: 80px;
    padding-bottom: 80px;

    @include media-breakpoint-down(md) {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    @include media-breakpoint-down(sm) {
        padding-top: 30px;
        padding-bottom: 30px;
    }
}

.py-30 {
    padding: 30px 0;

    @include media-breakpoint-down(md) {
        padding: 25px 0;
    }

    @include media-breakpoint-down(sm) {
        padding: 15px 0;
    }
}

.mb-40{
    margin-bottom: 40px;
    @include media-breakpoint-down(md) {
        margin-bottom: 26px;
    }

    @include media-breakpoint-down(sm) {
        margin-bottom: 22px;
    }
}

.pb-40{
    padding-bottom: 40px;
    @include media-breakpoint-down(md) {
        padding-bottom: 26px;
    }

    @include media-breakpoint-down(sm) {
        padding-bottom: 22px;
    }
}

.mx-10{
    margin: 10px auto;
}

.border-bottom{
    border-bottom: 2px solid #DDDEDE;
}

.mxw-16{
    max-width: 1rem; 
}

.text-underline{
    text-decoration: underline;
}