.banner{

    &__cabecera{

        position: relative;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        min-height: 438px; 
        @include media-breakpoint-down(md) { 
            min-height: 300px; 
        }
        @include media-breakpoint-down(sm) { 
            min-height: 350px;
        }

        .titulo {  
            font-weight: 900;
            letter-spacing: 0;
            @include fluid-font(32px, 80px);
        }

        .f-destacada{
            font-size: 18px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 21px;
        }

        .enlace{
            text-decoration: underline;
            transition: all ease .3s;
        }

        &__porque{
            background-image: url("../../../assets/img/por_que/cabecera-porque-alquilar.jpg");
            @include media-breakpoint-down(sm) { 
                background-image: url("../../../assets/img/por_que/cabecera-porque-alquilar-rwd.jpg");

            }
        }


        &__faqs{
            background-image: url("../../../assets/img/faqs/cabecera-FAQs.jpg");
            @include media-breakpoint-down(sm) { 
                background-image: url("../../../assets/img/faqs/cabecera-FAQs-rwd.jpg");

            }
        }
    }   

    &__buscador{

        &__contenedor{
            background-image: url("../../../assets/img/home/cabecera-Alquiler-ret.jpg");
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;

            min-height: 438px; 

            @include media-breakpoint-down(sm) {
                background-image: url("../../../assets/img/home/cabecera-alquiler.jpg");
                min-height: 426px; 
            }
            @include media-breakpoint-down(sm) {
                min-height: 375px; 
            }

            .buscador{
                display: flex;
                align-items: center;
                cursor: pointer;

                @include media-breakpoint-down(md) {
                    max-width: 95%;
                    margin: auto;
                }

                input{
                    width: calc(100% - 84px);
                    box-shadow: inset 1px -1px 3px 0 rgba(0,0,0,0.17);
                    height: 50px;
                    display: flex;
                    border-radius: 100px 0 0 100px;
                    padding: 10px 30px;
                    border: none !important;
                    font-size: 15px;
                    font-weight: 400;
                    letter-spacing: 0;

                    &::placeholder {
                        color: color("grey");
                    }

                    @include media-breakpoint-up(sm) {
                        min-width: 470px;
                    }

                    &:focus{
                        box-shadow: inset 1px -1px 3px 0 rgba(0,0,0,0.17);   
                    }
                }

                i{
                    box-shadow: inset 1px -1px 3px 0 rgba(0,0,0,0.17);
                    width: 84px;
                    height: 50px;
                    background-color: color("red");  
                    border-radius: 0 100px 100px 0;
                }
            }
        }  
    }

    &__cat-producto{
        background-image: url("../../../assets/img/general/cabecera-categoria-dumper.jpg");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        min-height: 239px; 

        > *{
            padding-left: 100px;
            padding-bottom: 35px;
        }
    }
} 